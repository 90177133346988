const styles = {
  base: {
    donationBlock: {
      backgroundColor: '#fffde2',
      maxWidth: '720px',
      margin: '35px auto',
      padding: '22px 30px',
    },
    itemImage: {
      maxHeight: '95px',
      width: 'auto',
    },
    itemTitle: {
      marginBottom: '15px',
    },
    matchUpTo: {
      marginTop: '15px',
    },
    itemDescription: {
      marginBottom: '35px',
    },
  },

  small: {
    donationBlock: {
      textAlign: 'center',
    },
    itemTitleColumn: {
      marginTop: '20px',
    },
    btn: {
      width: '100%',
    },
  },

  large: {
    donationBlock: {
      textAlign: 'left',
    },
    itemImageColumn: {
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '130px',
    },
    itemImageWrapper: {
      marginRight: '35px',
    },
    itemTitleColumn: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    itemTitle: {
      fontSize: '1.125em',
    },
  },
}

export default styles
