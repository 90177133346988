import MutationSummary  from 'mutation-summary'
import { h, render }    from 'preact'

import Donation from './Donation'

const PROD_ATTR = 'data-donate-via-pledgeling'
const LOAD_ATTR = 'data-stay-back-this-donation-is-loaded'

const script = document.currentScript
const url = new URL(script.src, location)
const origin = url.origin

const initDonation = (element, cart, moneyFormat) => {
  if (element.getAttribute(LOAD_ATTR)) return
  element.setAttribute(LOAD_ATTR, '1')

  const handle  = element.getAttribute(PROD_ATTR)
  if (!handle)
    return console.error('Product ID is missing from embed code.')

  const success = data => render(h(Donation, {
    ...data, cart, moneyFormat,
    matchUpTo: element.dataset.matchUpTo,
  }), element)

  fetch(`/products/${handle}`, { headers: { Accept: 'application/json' } })
  .then(response => {
    if (response.ok)
      response.json().then(success)
    else
      console.error('Fetching product failed.', handle, response)
  }).catch(error =>
    console.error('Fetching product failed due to network error.', handle, error)
  )
}

const initDonations = (cart, moneyFormat) => {
  const selector = `[${PROD_ATTR}]`
  const initWithData = element => initDonation(element, cart, moneyFormat)
  document.querySelectorAll(selector).forEach(initWithData)
  new MutationSummary({
    callback (summaries) {
      summaries[0].added.forEach(initWithData)
    },
    queries: [{ element: selector }]
  })
}

const fetchCart = moneyFormat => {
  fetch('/cart', { headers: { Accept: 'application/json' } }).then(response => {
    if (response.ok)
      response.json().then(json => initDonations(json, moneyFormat))
    else {
      console.warn("Fething cart failed. Round-up buttons won't render.", response)
      initDonations(null, moneyFormat)
    }
  }).catch(error => {
    console.error("Fething cart failed due to network error. Round-up buttons won't render.", error)
    initDonations(null, moneyFormat)
  })
}

export const init = () => {
  if (!origin)
    return console.error('Extracting script origin failed')

  if (typeof Shopify != 'object')
    return console.error('Shopify object not found')

  const url = `${origin}/shop/info/shop?shop=${encodeURIComponent(Shopify.shop)}`
  const success = data => data.currency.iso_code == Shopify.currency.active
  ? fetchCart(data.money_format)
  : console.error('Active currency does not match shop default currency. Donation buttons disabled.')

  fetch(url, { headers: { Accept: 'application/json' } }).then(response => {
    if (response.ok)
      response.json().then(success)
    else
      console.error('Fetching shop data failed.', response)
  }).catch(error =>
    console.error('Fetching shop data failed due to network error.', error)
  )
}
